.widget {
    background-color: white;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 2px 2px;
    min-height: 250px;
    padding: 32px 40px;
    margin: 17px 25px;
}
.widget-header {
    margin-bottom: 20px;
}
.widget-header h1 {
    font-size: 26px;
    line-height: 1;
    margin-bottom: 10px;
}
.widget-header p {
    color: grey;
    margin: 0;
}

@media only screen and (max-width: 600px) {
    .widget {
        margin: 15px 0;
        padding: 20px;
    }
}