.alert-parent{
  height:0
}
.alert-container{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  /* font-variant: tabular-nums; */
  line-height: 1.5715;
  list-style: none;
  /* font-feature-settings: 'tnum'; */
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1200;
  width: 100%;
  pointer-events: none;

}

.alert{
  padding: 8px;
    text-align: center;
}

.alert-notice{
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: all;
}
    /* @keyframes enter-leave {
      0% {
        top: -60px;
      }
      7.5% {
        top: 16px;
      }
      92.5% {
        top: 16px;
      }
      100% {
        top: -60px;
      }
    } */


  