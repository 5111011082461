.vciso-card {
    /* display: inline-block; */
    width: 325px;
    min-width: 325px;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0px 8px 8px -7px rgba(0, 0, 0, 0.1);
    margin: 30px;
    overflow:hidden;
    border:1px solid rgb(233, 233, 233);
  }
  
  .vciso-card-img {
      display:flex;
      align-items: center;
      justify-content: center;
      height: 60%;
      background-color: rgb(246, 246, 246);
   
  }
  .vciso-card-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
  
  .vciso-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    border-top: 0.5px solid rgb(210, 210, 210);
    height: 40%;
    overflow-wrap: break-word;
  }
  
  .vciso-card-title {
    font-weight: bold;
    font-size: 14px;
    /* margin-bottom: 5px; */
  }
  
  .vciso-card-more-info {
    color: #40a9ff;
    cursor:pointer;
    margin-top: 5px;
  }
  
  .vciso-card-more-info span {
      
    margin-left: 5px;
  }
  