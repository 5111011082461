.vendor-list-item-body{
    padding:15px
}



.vendor-list-item-body .item-row .item-keys{
   font-size:14px;
   font-weight: 600
}


.vendor-list-item-body .item-row{

  margin-bottom:5px
 }
 .vl-accordion-container {
  margin: 8px 0px;
  border-radius: 4px;
}

.vl-accordion-item {
  border-radius: 4px;
}

.vl-actions {
  display: flex;
  align-items: center;
}
.vl-edit-icon {
  padding: 0 20px;
  color:var(--cisobot-dark-color-blue);
}
.vl-delete-icon {
  padding: 0 20px;
}

.vl-actions {
  /* margin:0 25px; */
  cursor: pointer;
}

.vl-accordion-toggle {
  /* margin:0 25px; */
  cursor: pointer;
}

.vl-accordion-header {
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight:bold;
  background-color: #fafafa; ;
  border-radius: 4px;
  font-size: 14px;
  padding:3px 0px
}

.vl-accordion-header .vl-accordion-header-topic {
  padding: 5px;
  color:var(--cisobot-dark-color-blue);
}

.vl-accordion-toggle {
  background: none;
  border: none;
  color:var(--cisobot-dark-color-blue);
}

.vl-accordion-body {
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 4px;
}

/* the most important part */
.vl-accordion-container .open {
  width: 100%;
}
.vl-accordion-container .close {
  display: none;
}
