

.at-actions {
  /* margin:0 25px; */
  cursor: pointer;
}



.at-nested-accordion {
  margin-left: 30px;
  border-radius: 4px;
}
.at-accordion-header {
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  padding: 5px 12px;
  color:white;
  border-radius: 4px;
  margin: 2px;
}

.at-accordion-header-toggle {
  background: none;
  border: none;
  color:white;
  cursor: pointer;
  margin-right:10px
}
.at-accordion-header-name {
padding-right: 20px;
}

.at-accordion-body {
  border: none !important;
  border-top: none;
  border-radius: 4px;
}

/* the most important part */
.at-accordion-container .open {
  width: 100%;
}
.at-accordion-container .close {
  display: none;
}

