.dt-list {
  width: 100%;
  text-align: center;
  color: rgb(165, 165, 165);
  font-size: 16px;
  margin-top: 30px;
}

.show-less {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}
#technology-discovery-details-table {
  margin-bottom: 15px;
}
#technology-discovery-details-table thead {
  background-color: #c3d7e966;
}
#technology-discovery-details-table .tr-collapsed {
  border-bottom: 1px solid #b3d6f666;
}

#technology-discovery-table #collapsible-technology-discovery-table > tbody > tr > th {
  width: 35px;
  vertical-align: baseline;
}

#technology-discovery-table #collapsible-technology-discovery-table > tbody  > tr > td {
  vertical-align: baseline;
}

#technology-discovery-details-table .tr-collapsed:last-child {
  border-bottom: unset;
}

#technology-discovery-table button[disabled]{
 pointer-events: none
}


/* remove table rows in the technology-discovery-table  expandable row we dont want to show when screenwidth is bigger than 715px */

#technology-discovery-table
#collapsible-technology-discovery-table 
  > tbody
  > tr:nth-child(2) {
  display: none;
}

/* remove table rows in the technology-discovery-details-table  expandable row we dont want to show when screenwidth is bigger than 715px */

#technology-discovery-details-table tbody tr td:nth-child(1) {
  display: none;
}
#technology-discovery-details-table thead tr th:nth-child(1) {
  display: none;
}

@media (max-width: 760px) {
  #technology-discovery-table > thead > tr th:nth-child(3) {
    display: none;
  }

  #technology-discovery-table > tbody tr td:nth-child(3) {
    display: none;
  }
  #technology-discovery-table > thead > tr th:nth-child(2) {
    width: 100%;
  }

  #technology-discovery-table > thead > tr th:nth-child(2) {
    width: 100%;
  }

  #technology-discovery-table > thead > tr th:last-child {
    display: table-cell;
  }
  #technology-discovery-table > tbody > tr td:last-child {
    display: table-cell;
  }



  #technology-discovery-table #collapsible-technology-discovery-table > tbody > tr:nth-child(2){
    display:table-row
  }

  #technology-discovery-details-table tbody tr td:nth-child(1) {
    display: table-cell;
  }
  #technology-discovery-details-table thead tr th:nth-child(1) {
    display: table-cell;
  }
  #technology-discovery-details-table thead tr th:nth-child(2) {
    width: 100% !important;
  }

  #technology-discovery-details-table
  #collapsible-technology-discovery-details-table
    > tbody
    > tr
    th {
    width: 100px;
  }

  #technology-discovery-details-table
  #collapsible-technology-discovery-details-table
    > tbody
    > tr:last-child {
    border-bottom: none;
  }

  #technology-discovery-table
  #collapsible-technology-discovery-table
    > tbody
    tr:nth-child(5)
    > td {
    word-break: break-all;
  }

  #technology-discovery-details-table
    > thead
    > tr
    > th:not(:nth-child(1)):not(:nth-child(2)) {
    display: none;
  }

  #technology-discovery-details-table
    > tbody
    > tr
    > td:not(:nth-child(1)):not(:nth-child(2)) {
    display: none;
  }
}
