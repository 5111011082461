.vciso-profiles-container {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  align-content: center; 
   justify-content: center;
  align-items: center;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  grid-gap: 2rem;
  margin-block: 2rem;
  padding: 0 1rem;
  justify-items: center;
  align-items: center; */
}

