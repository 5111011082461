
.btn-base {
    border-radius: 4px;
    cursor: pointer;
    padding: 6px 12px;
    outline: none;
 
}
.btn-sm {
    font-size: 10px;

}
.btn-md {
    font-size: 12px;
}

.btn-lg {
    font-size: 14px;
    padding: 8px 15px
}

.btn-xlg {
    font-size: 16px;
    padding: 10px 17px
}



.button-type-default {
    background-color: #fafafa;
    border: 1px solid #c4c4c4;
}

.button-type-default:hover {
    color:var(--cisobot-secondary-color-blue);
    border: 1px solid var(--cisobot-secondary-color-blue);
}

.button-type-primary {
    border: none;
    color: #fff;
    background-color: var(--cisobot-secondary-color-blue);
}

.button-type-primary-dark {
    border: none;
    color: #fff;
    background-color: var(--cisobot-dark-color-blue);
}

.button-type-primary:disabled {
    cursor: default !important;
    background-color: #048edc75;

    color: #fff;
}

.button-type-submit {
    border: none;
    color: #fff;
    background-color: var(--cisobot-secondary-color-blue);
}

.button-type-primary-dark:disabled {
    cursor: default !important;
    background-color: #00699b8c;
    color: #fff;
}

.button-type-submit:disabled {
    cursor: default !important;
    background-color: #048edc75;
    color: #fff;
}


.button-type-submit-dark {
    border: none;
    color: #fff;
    background-color: var(--cisobot-dark-color-blue);
}
.button-type-submit-dark:disabled {
    cursor: default !important;
    background-color: #00699b8c;
    color: #fff;
}
