.tag-container {
  padding: 3px 7px;
  width: fit-content;
  border-radius: 4px;
  display:flex;
  flex-direction: row;
}

.tag-container-blue {
  border: 1px solid #76afc6;
  color: #67b1ce;
  background-color: #f0f5ff;
}
.tag-container-cyan {
  border: 1px solid #76afc6;
  color: #67b1ce;
  background-color: var(--cisobot-light-color-blue-2)
}
.tag-container-default {
    border: 1px solid #cccccc;
    color: #c6c6c6;
    background-color: #fbfbfb;
  }

.tag-close-separator {
  padding: 0px 7px;
}

.tag-close {
  padding-left:15px;
  cursor: pointer;

  /* padding-left:5px */
}
