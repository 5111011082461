.control {
  display: flex;
  /* align-items: stretch; */
  flex-direction: column;
}

.control label,
.control input {
  display: block;
}

.control label {
  font-weight: bold;
  margin-right: 10px;
  color: #464646;
  margin-bottom: 0.5rem;
}
.control label.required::before {
  content: "*";
  color: red;
  margin-right: 2px;
}

.control input {
 
  padding: 0.35rem 0.35rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.control input:focus {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0px 0px 5px #d1e9ff;
}
.control input:hover {
  outline: none;
  border-color: #40a9ff;
}

@media (min-width: 768px) {
  .control {
    align-items: center;
    flex-direction: row;
  }
}
