.status-form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.status-form textarea {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  font-family: "Ubuntu", sans-serif;
  font-size: inherit;
  max-height: 40vh;
  min-height: 120px;
  outline: none;
  resize: vertical;
}
.status-form .associate-vendor-list {
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  padding: 5px 12px;
 background-color: #f6f6f6;;
  border-radius: 4px;
  margin: 2px;
  font-size: 14px;


}
.status-form .associate-vendor-container {
max-height: 200px;
overflow-y: auto;
}