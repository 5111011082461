table {
  border-collapse: collapse;
  width: 100%;
  font-family: sans-serif;
}

td {
  padding: 10px 15px;
  text-align: left;
  white-space: pre-line;

}

th {
  position: relative;
  color: var(--cisobot-dark-color-blue);
  font-weight: 500;
  text-align: left;
  padding: 10px;
  overflow-wrap: break-word;

}
thead {
  background-color: #fafafa;
  font-size: 12px;
  color: var(--cisobot-dark-color-blue);
}

tbody {
  font-size: 12px;
  border-top: 1.1px solid rgb(216, 216, 216);
}
thead > tr > th:not(:last-child):before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: #0000000f;
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: "";
}

.tr-collapsed {
  border-bottom: 1px solid #efefef;
}

table .edit {
  width: 100px;
}
table .edit span:hover {
  cursor: pointer;
}
table .delete {
  width: 100px;
}
table .delete span:hover {
  cursor: pointer;
}

.collapsible-tr .collapsible-td {
  background-color: var(--cisobot-light-color-blue-2);
  
}

.collapsible-td > table tr {

  border-bottom: 1px solid #ebeef5;
}
.collapsible-td > table > tr > th {
  border-bottom: unset;
}
.collapsible-td > table > tr:last-child {
  border-bottom: unset;
}

.collapsible-td > table > tr > td {
  padding-left: 0px;
}

.collapse-icon-arrow-down {
  cursor: pointer;
}
.collapse-icon-arrow-right {
  cursor: pointer;
}
.collapsible-td > table tbody {
  border-top: none;
}

.ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /*No of lines after which the ellipsis needs to be added*/
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.vendor-discovery-table {
  font-size: 13px;
  margin-top: 10px;
  width: 100%;
}

/* .vendor-discovery-table thead tr th{} */

@media (max-width: 650px) {
  .vendor-discovery-table  .description-col{
  display: none;
  }
  .vendor-discovery-table  .Description-col-header{
    display: none;
    }

}