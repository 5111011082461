.userinfo {
    padding: 30px;
    margin: 0 20px 10px;
    border-bottom: 1px solid #e8e8e8;
}

.userinfo-popover-trigger {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.userinfo-popover-trigger > .fa-user-circle {
    color: dodgerblue;
    font-size: 20px;
}

.userinfo-popover-trigger > p {
    font-size: 16px;
    margin: 0 10px;
}

.userinfo-popover-trigger > .fa-chevron-down {
    font-size: 10px;
}

.userinfo-popover-content > * {
    color: #2a2a2a;
    cursor: pointer;
    padding: 12px 30px 12px 20px;
    text-decoration: none;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.userinfo-popover-content > *:hover {
    color: dodgerblue;
}