.alert-container-error {
    margin: 15px 0;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid red;
    background-color: #fff2f0;
    padding: 10px 12px;
    width: fit-content;
}

.alert-container-warning {
    margin: 15px 0;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #FFE58F;
    background-color: #fffbe6;
    padding: 10px 12px;
}

.alert-container-info {
    margin: 15px 0;
    font-size: 14px;
    border-radius: 4px;
    background-color: #E6F7FF;
    border: 1px solid #91D5FF;
    padding: 10px 12px;
}

.alert-container-success {
    margin: 15px 0;
    font-size: 14px;
    border-radius: 4px;
    background-color: #F6FFED;
    border: 1px solid #B7EB8F;
    padding: 10px 12px;
}

