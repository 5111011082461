.sign-background {
    height: 100vh;
}

.sign-outer-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.sign-inner-container {
    border-radius: 12px;
    box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
    display: flex;
    min-height: 350px;
}

.sign-header {
    color: white;
    background-color: #4eb0e8;
    border-radius: 12px 0 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 35px 35px 30px;
    min-width: 220px;
}
.sign-header h1 {
    color: white !important;
    font-size: 30px;
    font-weight: normal;
}
.sign-header h2 {
    font-weight: normal;
}

.sign-logo-container {
    text-align: center;
    margin-bottom: 35px;
    width: 100%;
}
.sign-logo-container img {
    width: 210px;
}

.sign-body {
    background-color: white;
    border-radius: 0 12px 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px 35px;
    width: 405px;
    text-align: center;
}

.sign-body .input-icon-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.sign-body .input-icon-wrapper > i {
    color: #009ef6;
    position: relative;
    margin-left: 15px;
    width: 0;
    margin-right: -15px;
    z-index: 10;
}

.sign-body input {
    font-size: 15px;
    outline: none;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 10px 11px 10px 37px;
    background-color: #f5f5f5;
    transition: border .2s ease;
}
.sign-body input:focus, .sign-body input:hover {
    border: 1px solid #009ef6;
}

.sign-body button {
    font-size: 16px;
    height: 36px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 6px;
    width: 100%;
    background-color: #009ef6;
}

.sign-header a {
    font-size: 16px;
    margin-top: 40px;
    color: #fff;
}

.sign-signup-success h2 {
    font-size: 20px;
}
.sign-signup-success p {
    font-size: 16px;
}
.sign-signup-success strong {
    word-break: break-all;
}

.login-form-button.button-loading {
    background-color: #0089d2;
    cursor: default;
}
.button-spinner {
    margin-right: 5px;
    animation: button-spinner 1s linear 0s infinite forwards;
}
@keyframes button-spinner {
    from {
        transform: rotateZ(0deg);
    }
    to {
        transform: rotateZ(360deg);
    }
}

@media only screen and (max-width: 750px) {
    .sign-inner-container {
        flex-direction: column;
        width: 90%;
    }

    .sign-header {
        align-items: center;
        border-radius: 12px 12px 0 0;
        text-align: center;
        width: unset;
    }

    .sign-body {
        border-radius: 0 0 12px 12px;
        width: unset;
    }
}

.reset-password-content , h2{
    margin-bottom: 32px;
}