.container {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: max-content;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    box-shadow: rgba(169, 169, 169, 0.16) 0px 1px 4px;
  }

  .slider-text {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 10px;
  }

  .component {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .slider {
    position: relative;
    width: 200px;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: relative;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }
  
  .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }
  
  .slider__range {
    background-color: rgb(86, 131, 214);
    bottom: 5px; 
    z-index: 1;
  }
  
  .slider__min-max-value {
    display: flex;
    font-size: 15px;
    justify-content: space-between;
    margin-top: 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Removing the default appearance */
  /* .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  } */
  
  
  .thumb {
    pointer-events: none;
    position: relative;
    height: 0;
    top: 5px;
    width: 200px;
    outline: none;
  }

  .thumb-2 {
    margin-right: -175px;
    right: 200px;
  }

  .thumb--zindex {
    z-index: 3;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  