.chart-interaction-directions {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: fit-content;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  box-shadow: rgba(169, 169, 169, 0.16) 0px 1px 4px;
}

.chart-interaction-directions .priority-key {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}

.chart-interaction-directions .priority-key span {
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center
}

.chart-interaction-directions>.status-legend {
  padding: 10px 10px 10px 10px;
}

.status-legend>span {
  font-size: 14px;
}

.status-legend>span .not-applicable-dot {
  color: #c3c3c1;

}

.status-legend>span .to-do-dot {
  margin-left: 15px;
  color: #ff0d0d;
}

.status-legend>span .in-progress-dot {
  color: #ff8e15;
  margin-left: 15px;
}

.status-legend>span .resolved-dot {
  color: #26d701;
  margin-left: 15px;
}


.alert.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: fit-content;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: left;
}