.tree-container {
    overflow-x: scroll;
}

.tree-control-buttons {
    margin-bottom: 20px;
}
.tree-control-buttons > button {
    margin-right: 10px;
}

.tree-item-container {
    margin-left: 50px;
}

.tree-hidden {
    display: none;
}

.tree-item {
    background-color: #f7f7f7;
    border-radius: 6px;
    box-shadow: 1px 2px 4px #949494;
    color: #fff;
    display: flex;
    justify-content: space-between;
    margin: 5px;
    min-height: 33px;
    min-width: fit-content;
    width: 30%;
    position: relative;
}
.tree-item-left, 
.tree-item-right {
    align-items: center;
    display: flex;
}
.tree-item-left {
    margin-right: 50px;
}
.tree-item-name, 
.tree-item-arrow-button, 
.tree-item-edit {
    padding: 6px 15px;
}
.tree-item-arrow-button, 
.tree-item-edit {
    background-color: #ffffff00;
    cursor: pointer;
    color: white;
}
.tree-item-arrow-button {
    border: none;
    font-size: 18px;
    margin-right: -10px;
    position: relative;
    z-index: 2;
}
.tree-item-priority {
    height: 26px;
    margin-left: 10px;
}
.tree-item-name-parenthesis {
    color: #ebebeb;
}
.tree-item-edit {
    border: none;
    font-weight: bold;
}
.tree-item-edit:hover {
    text-decoration: underline;
}
.tree-item-status {
    font-size: 16px;
    line-height: 16px;
    text-shadow: 1px 1px 4px #7e7e7e;
}

.tree-item .tooltiptext {
visibility: hidden;
width: 200px;
background-color: #555;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 0;
position: absolute;
/* z-index: 1; */
bottom: 125%;
left: 50%;
margin-left: -60px;
opacity: 0;
transition: opacity 0.3s;
}

.tree-item .tooltiptext::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #555 transparent transparent transparent;
}

.tree-item:hover .tooltiptext {
visibility: visible;
opacity: 1;
}

@media only screen and (min-width: 1000px) {
    .tree-container {
        overflow-x: hidden;
    }
    .tree-item {
        width: 400px;
    }
}