.layout, .layout-content-sidebar-row {
    min-height: 100vh;
    max-height: 100vh;
}

.layout-content-sidebar-row {
    display: flex;
    padding-top: 70px;
    width: 100%;
}

.layout-content {
    overflow-y: scroll;
    width: 100%;
}