:root{
    --cisobot-main-color-blue:dodgerblue;
    --cisobot-secondary-color-blue: #038fde;
    --cisobot-dark-color-blue:#00699b;
    --cisobot-light-color-blue-1:#e6faff;
    --cisobot-light-color-blue-2:#f0fcff;
}

body {
    margin: 0;
    color: #2a2a2a;
    font-size: 14px;
    font-family: "Ubuntu", sans-serif;
    font-variant: tabular-nums;
    line-height: 1.3;
    background-color: #eaeaea;
    -webkit-font-feature-settings: "tnum","tnum";
    font-feature-settings: "tnum","tnum";
    overflow-y: clip;
}

* {
    margin: 0;
    box-sizing: border-box;
}

