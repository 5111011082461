.sidebar-link > a {
    align-items: center;
    color: #545454;
    cursor: pointer;
    display: flex;
    padding: 12px 10px 12px 20px;
    text-decoration: none;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    display: flex;
    justify-content: space-between;
}

.sidebar-link > a:hover {
    color: var(--cisobot-main-color-blue);
    background-color: #fafafa;
}


.sidebar-link.sidebar-link-selected > a {
    background-color: #e6faff !important;
}
.sidebar-link-group-name-icon{
display:flex
}
.sidebar-link-name{
text-align: center;
}

.sidebar-link-icon {
    align-items: center;
    color: var(--cisobot-main-color-blue);
    display: flex;
    font-size: 20px;
    min-width: 20px;
    margin-right: 15px;
}

.sidebar-link-icon > i::before , .sidebar-link-icon > svg {
    font-weight: 700;
    height: 20px;
    width:20px
    
}


.sidebar-link-group-arrow {
    margin-right: 10px;
    margin-left: 10px;
    font-size:10px
}



