.select-container {
    cursor: pointer;
    font-size: 16px;
    width: 250px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.select-box {
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    transition: border-color .1s ease-in;
}
.select-box:hover {
    border-color: #40A9FF;
}
.select-box > i.fa-caret-down,
.select-box > i.fa-caret-up {
    font-size: 18px;
}
.select-menu-outer {
    height: 0;
    margin-top: 2px;
    margin-bottom: -2px;
    position: relative;
    width: 100%;
    z-index: 500;
}
.select-menu-inner {
    background-color: white;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 0px #A7A7A7;
}
.select-menu-item {
    border-radius: inherit;
    padding: 8px 15px;
    transition: background-color .1s ease-in;
}
.select-menu-item.selected,
.select-menu-item.selected:hover {
    background-color: #ECF6FF;
}
.select-menu-item:hover {
    background-color: #EEEEEE;
}