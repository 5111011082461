.http-checkbox-label {
  display: block;
  position: relative;
  /* margin: auto; */
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
}

.http-checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.http-checkbox-label .http-checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #3C9AF8;
  background-color: #FFFFFF;
}


.http-checkbox-label input:checked ~ .http-checkbox-custom {
  background-color: #FFFFFF;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity:1;
  border: 2px solid #3480cc
}


.http-checkbox-label .http-checkbox-custom::after {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #FFFFFF;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity:1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}


.http-checkbox-label input:checked ~ .http-checkbox-custom::after {
-webkit-transform: rotate(45deg) scale(1);
-ms-transform: rotate(45deg) scale(1);
transform: rotate(45deg) scale(1);
opacity:1;
left: 5px;
top: .5px;
width: 6px;
height: 12px;
border: solid #009BFF;
/* border: solid #FFFFFF; */
border-width: 0 2px 2px 0;
background-color: transparent;
border-radius: 0;
}



/* For Ripple Effect */
.http-checkbox-label .http-checkbox-custom::before {
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #FFFFFF;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);  
}

.http-checkbox-label input:checked ~ .http-checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity:0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}




/* Style for Circular Checkbox */
/* .http-checkbox-label .http-checkbox-custom.circular {
  border-radius: 50%;
  border: 2px solid #FFFFFF;
}

.http-checkbox-label input:checked ~ .http-checkbox-custom.circular {
  background-color: #FFFFFF;
  border-radius: 50%;
  border: 2px solid #FFFFFF;
}
.http-checkbox-label input:checked ~ .http-checkbox-custom.circular::after {
  border: solid #0067FF;
  border-width: 0 2px 2px 0;
}
.http-checkbox-label .http-checkbox-custom.circular::after {
  border-radius: 50%;
}

.http-checkbox-label .http-checkbox-custom.circular::before {
  border-radius: 50%;
  border: 2px solid #FFFFFF;
}

.http-checkbox-label input:checked ~ .http-checkbox-custom.circular::before {
  border-radius: 50%;
} */