.domains-container {
  border: 1px solid rgb(221, 221, 221);
  padding: 8px;
  margin-top: 10px;
  width: 100%;
  border-radius: 4px;
}

.dc-tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  text-align: center;
}
.dc-add-tag {
  border: 1px dashed #c9c9c9;
  color: #c9c9c9;
  margin: 5px 2px;
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 4px;
}

.dc-add-tag:hover {
  color: var(--cisobot-secondary-color-blue);
  border: 1px dashed var(--cisobot-secondary-color-blue);
}

.invalid-domain {
  color: red;
  padding: 5px;
}
