.sidebar {
    --sidebar-max-width: 310px;
    background-color: #fff;
    max-width: var(--sidebar-max-width);
    min-width: 280px;
}

.sidebar-closed {
    height: 100%;
    left: calc(var(--sidebar-max-width) * -1);
    position: fixed;
    z-index: 1000;
}



@media only screen and (max-width: 1000px) {
    .sidebar {
        box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
        height: 100%;
        position: fixed;
        z-index: 1000;
    }
}