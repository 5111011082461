.dt-list{
    margin-top :20px
}
/* .rediscover-vendors-container{
    border: 1px solid #ccc;
    padding: 10px;
    width: fit-content;
    border-radius: inherit;
    box-shadow: rgb(169 169 169 / 16%) 0px 1px 4px;
} */

/* .rediscover-vendors-container{
border: 1px solid rgb(0, 149, 255);
padding:10px
} */
.discover-action-container{
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.discover-action-button-icon{
  display: flex;
  align-items: center;
}

.discover-action-container-first-discovery{
  display: flex;
  align-items: center;
  justify-content: center;

}


@media (max-width: 650px) {
  .discover-action-container{
    justify-content: baseline;
  flex-direction: column;
  align-items: flex-start;
  }

}

/* /*search=animation================================================================== */


@keyframes ldio-3a5b6kg4xve {
    0%    { transform: translate(1px,1px) }
   33.33% { transform: translate(51px,1px) }
   66.66% { transform: translate(21px,51px) }
  100%    { transform: translate(1px,1px) }
}


.ldio-3a5b6kg4xve > div {
  transform: scale(0.82);
  transform-origin: 50px 50px;
}

.ldio-3a5b6kg4xve > div > div {
  animation: ldio-3a5b6kg4xve 1.8518518518518516s linear infinite;
  position: absolute
}

.ldio-3a5b6kg4xve > div > div div:nth-child(1) {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 6px solid #587283;
  background: #b9d8e9
}

.ldio-3a5b6kg4xve > div > div div:nth-child(2) {
  width: 8.5px;
  height:  25.5px;
  transform: rotate(-45deg);
  background: #587283;
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: 34px;
  left: 42.5px
}
.loadingio-spinner-magnify-9c7iczz11rf {
  width: 31px;
  height: 24px;
  display: inline-block;
  /* overflow: hidden; */
  background: #ffffff;
}
.ldio-3a5b6kg4xve {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.31);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-3a5b6kg4xve div { box-sizing: content-box; }
/* generated by https://loading.io/ */
