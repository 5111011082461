.topbar {
    height: 70px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1100;
    display: flex;
    background-color: white;
    box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
    align-items: center;
    padding: 0 20px;
}

.topbar-sidebar-toggle {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    margin-right: 15px;
}

.topbar-sidebar-toggle:hover {
    background-color: #fafafa;
}

.topbar-sidebar-toggle > img {
    height: 24px;
}