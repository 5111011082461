.vciso-profile-modal {
  width: 900px;
  display: flex;
  height: 400px;
  border: 0.5px solid rgb(228, 228, 228);
  border-radius: 10px;
  overflow: hidden;
}

.vciso-modal-card-left {
  width: 40%;
  border-right: 0.5px solid rgb(228, 228, 228);

}

.vciso-modal-card-right {
  width: 60%;
  overflow-y: auto;
}

.vciso-modal-card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: rgb(246, 246, 246);
}
.vciso-modal-card-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.vciso-modal-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border-top: 0.5px solid rgb(210, 210, 210);
  overflow-wrap: break-word;
}

.vciso-modal-card-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}
.vciso-modal-card-title-line {
  margin-bottom: 5px;
}
.vciso-modal-card-email {
  margin-bottom: 5px;
}

.vciso-modal-card-description {
  padding: 10px;
  

}

@media (max-width: 750px) {
  .vciso-profile-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
 max-width: 350px;
    height: 400px;
    overflow-y: auto;
    margin: 0px;
  }

  .vciso-profile-modal .vciso-modal-card-left {
    width: 100%;
  }

  .vciso-profile-modal .vciso-modal-card-right {
    border-top:1px solid  rgb(210, 210, 210);
    align-self: baseline;
    width: 100%;
    /* display: inline-block; */
    /* width:50% */
    /* width: 300px; */
    /* min-width: 300px; */
    /* border-radius: 20px; */
    /* box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1); */
    /* margin: 30px; */
    /* overflow:hidden */
    /* position: relative; */
    /* border:1px solid black; */
  }
  .vciso-modal-card-description {
    width:100%
  }
}
