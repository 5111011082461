.modal-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(62 62 62 / 35%);
  z-index: 1101;
}

.modal-inner {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.modal-box {
  background-color: white;
  border-radius: 4px;
  border:1px solid rgb(226, 226, 226);
  margin:15px;
  min-width: 350px;
max-width: 1000px;
  position: relative;
  z-index: 1102;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-bottom: 1px solid rgb(226, 226, 226);
}
.modal-body {
  padding: 15px;
  white-space: pre-line;
}
.modal-header-name{
  font-size: 16px;
  font-weight: bold;
  /* margin: 0;
    padding-right: 40px; */
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.modal-header-close-icon{
  font-size:25px;
  color:grey
}